<template>
  <b-button
    size="lg"
    class="btn-icon d-flex align-items-center justify-content-center"
    pill
    block
    :variant="buttonColor"
    @click="clickAction"
  >
    <FeatherIcon
      :icon="buttonIcon"
      size="24"
      class="mr-2"
    />
    {{ buttonText }}
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ActionButton',
  components: {
    BButton,
  },
  props: {
    buttonColor: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    clickAction: {
      type: Function,
      required: true,
    },
  },
}
</script>
