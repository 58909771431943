<template>
  <div>
    <b-card>
      <work-order :data-item="dataItem" />
      <worker-form :get-data="getData" />
    </b-card>
    <b-card>
      <work-lines :data-item="dataItem" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import WorkerForm from '@/views/Service_work_orders/Orders/WorkerView/WorkerForm.vue'
import WorkOrder from '@/views/Service_work_orders/Orders/WorkerView/WorkOrder.vue'
import WorkLines from '@/views/Service_work_orders/Orders/WorkerView/WorkLines.vue'

export default {
  name: 'WorkerView',
  components: {
    BCard,
    WorkerForm,
    WorkOrder,
    WorkLines,
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('serviceWorkOrders/getDataItem', this.$route.params.id)
        .then(res => {
          if (res) {
            this.getLastLine(res.id)
          }
        })
    },
    getLastLine(id) {
      this.$store.dispatch('serviceWorkOrderLines/getDataItem', {
        select: [
          'com_service_work_order_lines.id AS id',
          'com_service_work_order_lines.sdate AS sdate',
          'com_service_work_order_lines.edate AS edate',
          'com_service_work_order_lines.is_finish AS is_finish',
        ],
        where: {
          'com_service_work_order_lines.id_com_service_work_orders': id,
        },
        order_by: ['com_service_work_order_lines.id', 'DESC'],
        array: false,
      })
    },
  },
}
</script>
