<template>
  <div>
    <template v-if="loading">
      <div class="text-center">
        <div>
          <b-spinner variant="danger" />
        </div>
        <div class="font-weight-bold mt-1 text-danger">
          Yükleniyor...
        </div>
      </div>
    </template>
    <template v-else>
      <b-row>
        <template v-if="lineItem.edate">
          <b-col
            cols="12"
          >
            <action-button
              v-if="!lineItem.is_finish"
              button-icon="PlayCircleIcon"
              button-text="BAŞLAT"
              button-color="success"
              :click-action="workStart"
            />
            <b-alert
              v-else
              variant="danger"
              show
            >
              <div class="alert-body text-center">
                <FeatherIcon icon="InfoIcon" />
                İş Emiri kapatılmıştır. Bu iş emri için işlem yapamazsınız.
              </div>
            </b-alert>
          </b-col>
        </template>
        <template v-else-if="lineItem.sdate">
          <b-col
            cols="12"
            md="6"
          >
            <action-button
              button-icon="PauseCircleIcon"
              button-text="DURAKLAT"
              button-color="info"
              :click-action="workPause"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <action-button
              button-icon="StopCircleIcon"
              button-text="BİTİR VE SONLANDIR"
              button-color="danger"
              :click-action="workStop"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col
            cols="12"
          >
            <action-button
              v-if="!lineItem.is_finish"
              button-icon="PlayCircleIcon"
              button-text="BAŞLAT"
              button-color="success"
              :click-action="workStart"
            />
          </b-col>
        </template>
      </b-row>
      <div
        v-if="duration"
        class="text-center mt-5 border border-primary rounded py-2 bg-gradient-primary"
      >
        <div>
          <FeatherIcon
            icon="ClockIcon"
            size="24"
          />
        </div>
        <div class="font-medium-2 font-weight-light text-white">
          Geçen Süre
        </div>
        <div class="font-weight-bolder font-large-1 text-white pt-1">
          {{ duration }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BSpinner,
} from 'bootstrap-vue'
import ActionButton from '@/views/Service_work_orders/Orders/WorkerView/ActionButton.vue'

export default {
  name: 'WorkerForm',
  components: {
    BRow,
    BCol,
    BAlert,
    BSpinner,
    ActionButton,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      duration: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    lineItem() {
      return this.$store.getters['serviceWorkOrderLines/dataItem']
    },
    loading() {
      return this.$store.getters['serviceWorkOrderLines/loading']
    },
  },
  mounted() {
    this.setDuration()
  },
  methods: {
    setDuration() {
      setInterval(() => {
        if (!this.loading) {
          if (this.lineItem.edate === null) {
            if (this.lineItem.sdate) {
              this.duration = this.moment.utc(this.moment.duration(this.moment().diff(this.lineItem.sdate)).asMilliseconds()).format('HH:mm:ss')
            }
          } else {
            this.duration = null
          }
        }
      }, 1000)
    },
    workStart() {
      this.$store.dispatch('serviceWorkOrderLines/workStart', {
        id_com_service_work_orders: this.dataItem.id,
      })
        .then(res => {
          if (res) {
            this.getLastLine(this.dataItem.id)
            this.getData()
          }
        })
    },
    workPause() {
      this.$store.dispatch('serviceWorkOrderLines/workPause', {
        id: this.lineItem.id,
      })
        .then(res => {
          if (res) {
            this.getLastLine(this.dataItem.id)
            this.getData()
          }
        })
    },
    workStop() {
      this.$store.dispatch('serviceWorkOrderLines/workStop', {
        id: this.lineItem.id,
      })
        .then(res => {
          if (res) {
            this.getLastLine(this.dataItem.id)
            this.getData()
          }
        })
    },
    getLastLine(id) {
      this.$store.dispatch('serviceWorkOrderLines/getDataItem', {
        select: [
          'com_service_work_order_lines.id AS id',
          'com_service_work_order_lines.sdate AS sdate',
          'com_service_work_order_lines.edate AS edate',
          'com_service_work_order_lines.is_finish AS is_finish',
        ],
        where: {
          'com_service_work_order_lines.id_com_service_work_orders': id,
        },
        order_by: ['com_service_work_order_lines.id', 'DESC'],
        array: false,
      })
    },
  },
}
</script>
